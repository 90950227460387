import * as BS from "react-bootstrap";
import * as RRDom from "react-router-dom";
import styles from './ServicesBar.module.css';

const ServicesBarItem = (props: any) => {
  return (

    <RRDom.NavLink to={props.HRef} className="text-decoration-none text-reset">
      <BS.Container className={styles['square-style'] + ' py-5 px-4 '}
      >
        <div className={styles.content}>
          <img className="d-inline-block align-top mb-2" width={48} src={props.SignPath} alt="logo" />
          <h5>
            {props.Title}
          </h5>
          <p className={styles['short-text']}>
            {props.ShortText}
          </p>
          <p className={styles['long-text']}>
            {props.children}
          </p>
        </div>
      </BS.Container>
    </RRDom.NavLink>
  );
}

export default ServicesBarItem;


import * as BS from "react-bootstrap";

import CalcProjData from "./CalcProjData";
import SendMail from "./SendMail";

//import AboutBar from '../About/AboutBar'

const CalculateProjectForm = () => {

  // async function CheckoutOrderClicked() {
  //   //CheckoutOrderClickedHandle(mainState.FormData);
  //   window.alert("CheckoutOrderClicked");
  // }

  async function handleSubmit(e: any) {
    e.preventDefault()

    let formData: CalcProjData = 
    {
      Name: e.target.elements.name.value,
      CompanyName: e.target.elements.company_name.value,
      Phone: e.target.elements.phone.value,
      EMail: e.target.elements.email.value,
      TaskDescription: e.target.elements.task_description.value,
    };

    let result: string = await SendMail(formData);

    if (result == "")
    {
      window.alert("Заявка успешно отправлена.");          
    } else
    {
      let text: string = "Не удалось отправить заявку.\n Текст ошибки: \n " + result;
      window.alert(text);          
    }
    //console.log({result: result });

    // const {name, company_name } = e.target.elements
    // console.log({name: name.value, company_name: company_name.value })
  }

  return (
    <BS.Container className="px-0">
      <h1 className="text-center section-title">Найти ключ эффективности</h1>
      <BS.Row>
        <BS.Col md={2} className='py-2'>
        </BS.Col>

        <BS.Col md={8} className='py-2'>
          Предварительная бесплатная консультация – сбор проблематики.
          Если мы понимаем, что можем решить вашу проблему – сделаем предложение на необходимый пул работ.
          <br />
          <br />

                {/* <BS.Form.Group className="mb-3" controlId="formBasicEmail">
                  <BS.Form.Label>Фамилия</BS.Form.Label>
                  <BS.Form.Control type="text" name="surname" placeholder="Фамилия" />
                </BS.Form.Group> */}

          <BS.Form className="border rounded p-3" 
                  //  action="api/send.php" 
                  //  method="post"
                   onSubmit={handleSubmit}
                   >

            <BS.Row >
              <BS.Col md={6} className='py-0'>

                <BS.Form.Group className="mb-3" controlId="formBasicPassword">
                  <BS.Form.Label className="form-control-sm" >Имя</BS.Form.Label>
                  <BS.Form.Control className="form-control-sm" type="text" name="name" placeholder="Имя" />
                </BS.Form.Group>

                <BS.Form.Group className="mb-3">
                    <BS.Form.Label className="form-control-sm" >Компания</BS.Form.Label>
                    <BS.Form.Control className="form-control-sm" type="text" name="company_name" placeholder="Компания" />
                  </BS.Form.Group>
              </BS.Col>

              <BS.Col md={6} className='py-0'>
                <BS.Form.Group className="mb-3">
                  <BS.Form.Label className="form-control-sm" >Телефон</BS.Form.Label>
                  <BS.Form.Control className="form-control-sm" type="text" name="phone" placeholder="Телефон" />
                </BS.Form.Group>

                <BS.Form.Group className="mb-3" controlId="formBasicEmail">
                  <BS.Form.Label className="form-control-sm" >E-Mail</BS.Form.Label>
                  <BS.Form.Control className="form-control-sm" type="email" name="email" placeholder="E-Mail" />
                </BS.Form.Group>
              </BS.Col>

              {/* <BS.Row >
                <BS.Col md={12} className=''>
                  <BS.Form.Group className="mb-3">
                    <BS.Form.Label>Компания</BS.Form.Label>
                    <BS.Form.Control type="text" name="company_name" placeholder="Компания" />
                  </BS.Form.Group>
                </BS.Col>
              </BS.Row> */}

              <BS.Row >
                <BS.Col md={12} className=''>
                  <BS.Form.Group className="mb-3">
                    <BS.Form.Label className="form-control-sm" >Задача</BS.Form.Label>
                    <BS.Form.Control 
                      className="form-control-sm" 
                      as="textarea" 
                      name="task_description" 
                      rows={3} 
                      placeholder="Опишите вашу задачу" />
                  </BS.Form.Group>
                </BS.Col>
              </BS.Row>
            </BS.Row>

            <br />
            <div className="d-grid gap-2 col-4 mx-auto">
              <BS.Button 
                className="form-control-sm" 
                type="submit" 
                // type="button" 
                variant="primary" 
                style={{
                  backgroundColor: "#2E004B",
                }}     
                // onClick={CheckoutOrderClicked}

                          >
                Отправить заявку
              </BS.Button>
            </div>

          </BS.Form>
        </BS.Col>

        <BS.Col md={2} className='py-2'>
        </BS.Col>
      </BS.Row>
    </BS.Container>
  );
}

export default CalculateProjectForm;
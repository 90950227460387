import logo from '../keliv-logo.svg';
import * as BS from "react-bootstrap";
//import * as RR from 'react-router-dom';

//import Portfolio from './Portfolio'
//import Navigationbar from './Navigationbar'

const NavBarAndContent = (props: any) => {
//  let params = RR.useParams();

  return (
    <div>
      {/* <Navigationbar topAbsolute={props.navTopAbsolute} /> */}
      {props.contentElement}
      {/* <Routes>
        <Route path='/portfolio' element={props.contentElement} />
      </Routes> */}
    </div>
  );
}

export default NavBarAndContent;
//import HomeCarousel from './HomeCarousel'
import Home00 from './Home00'
import Home01 from './Home01'
import ServicesBar from './Services/ServicesBar'
import OurWorksBar from './OurWorks/OurWorksBar'
import AboutUsAndFotosBar from './AboutUsAndFotos/AboutUsAndFotosBar'
import CalculateProjectBar from './CalculateProject/CalculateProjectBar'
import TeamBar from './OurTeam/TeamBar'
import OurClientsBar from './OurClients/OurClientsBar'
import LentaTextsBar from './LentaTexts/LentaTextsBar'

//import * as BS from "react-bootstrap";

const Home = () => {
  return (
    //<div className="bg-dark text-white screen-block"></div>
    <div>
      <div className="bg-dark text-white screen-block">
        <Home00 />
      </div>
      
      <LentaTextsBar />

      <Home01 />

      <ServicesBar />

      <OurWorksBar 
        BackgroundStyle="Gray" />

      <AboutUsAndFotosBar  />
      <TeamBar/>

      <OurClientsBar/>

      <CalculateProjectBar />
    </div>
  );
}

export default Home;
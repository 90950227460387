import CalcProjData from "./CalcProjData";

type SendMailApiData =
  {
    SendToFirstEmail: boolean;
    SendToSecondEmail: boolean;
    Subject: string;
    BodyText: string;

    Attachment: string;
    AttachmentFilename: string;
    AttachmentTypeName: string;
  };


function ObjectValueAsStringList(formValues: CalcProjData): string {
  let fv: any = formValues;
  let res: string = "";
  // let sysValue = fv.toJSON();
  //let sysValue =JSON.stringify(formValues);

  for (const [key, value] of Object.entries(formValues)) {
    res = res + `[${key}] = '${value}' \n`;
  }

  return res;
}

// function GetMailJSonText(fromValues: CalcProjData): string {
//   return JSON.stringify(fromValues);
// }

async function SendMail(formValues: CalcProjData) : Promise<string> {

  let smData: SendMailApiData = {
    SendToFirstEmail: false,
    SendToSecondEmail: true,
    Subject: "",
    BodyText: "",
    Attachment: '',
    AttachmentFilename: '',
    AttachmentTypeName: '',
  }

  // let payMethId: string = formValues.PaymentMethodID;
  // let typCode: string = formValues.SoftLicenTypeID;
   let eMail: string = formValues.EMail;

  // smData.Subject = `[CalcFormRequest]-[${payMethId}]-[${typCode}]-[${eMail}]`;
  smData.Subject = `[CalcFormRequest from ${window.location.hostname}]-[${eMail}]`;
  smData.BodyText = ObjectValueAsStringList(formValues);
  // smData.Attachment = GetMailJSonText(formValues);
  // smData.AttachmentFilename = "EhLibCustomPaymentinfo.Json";
  // smData.AttachmentTypeName = "application/json";


  //sendgrid.send(options);

  let sendMailHost: string;
  let isLocalHost: boolean; //Environment.GetEnvironmentVariable("ASPNETCORE_ENVIRONMENT") == "Development";
  if (window.location.hostname === 'localhost')
    isLocalHost = true
  else  
    isLocalHost = false;

  if (isLocalHost)
    sendMailHost = "http://localhost:3500";
  else
    sendMailHost = window.location.origin;

  let sendPath = sendMailHost + "/api/send.php";

  let jsonStr = JSON.stringify(smData);

  try {

    const response = await fetch(sendPath, {
      //mode: 'no-cors',
      method: 'POST',
      body: jsonStr,
      //body: smData as any,
      headers: {
        // "Access-Control-Allow-Origin": "*",
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
    
    //console.log(response); //
    //console.log(response.body);

    if (response.status !== 200)
    {
      //let result: string = await response.json() as string;
      let result: string =  
        'sendPath: ' + sendPath + '\n' + 
        'Error code: ' + response.status.toString() + '\n' + 
        await response.text();
      return result;
    }

  } catch (error) {
    if (typeof error === "string") {
        return 'sendPath: ' + sendPath + '\n' + 
               error;
    } 
    else if (error instanceof Error) {
      return 'sendPath: ' + sendPath + '\n' + 
             error.message;
    } 
    else {
      return "Error: Ошибка отправки сообщения. \n" +
      'sendPath: ' + sendPath + '\n' + 
      "Не удается получить текст ошибки.";
    }
  }

  return "";

  //axios.post(sendPath, smData);
}

export default SendMail;
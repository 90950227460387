import * as BS from "react-bootstrap";
import { Link } from "react-router-dom";

import back_image from '../assets/happy-family-with-child-center.jpg';
// import logo from '../../assets/2logo.png';
import NavigationBar from './NavigationBar/NavigationBar';

//marginTop: "auto", marginBottom: "auto",

const HomeCarouselCaption = () => {
  return (
    <BS.Stack className="h-100">
      <NavigationBar />
      {/* <div className=" my-auto "> */}
      <div className="flex-fill align-content-center" style={{ margin: "50px 50px 50px 50px", }} >
        <div>
          <p style={{
            fontSize: "3.0em",
            fontWeight: "800",
            lineHeight: "1em",
            textShadow: "6px 6px 10px rgba(0, 0, 0, 0.2)",
            color: "#2E004B",
          }}>

            <span>Построение <br /> пищевых брендов <br />для работы с сетями под ключ</span>
          </p>
          <br />
          <h3 style={{
            lineHeight: "1em",
            textShadow: "6px 6px 10px rgba(0, 0, 0, 0.2)",
            // color: "#2E004B",
          }}>
            <p>Маштабирующие решения для увеличения прибыли производителя в сетевом ритейле*:</p>
          </h3>
          <br />
          <h5 style={{
            textShadow: "6px 6px 10px rgba(0, 0, 0, 0.2)",
            color: "#2E004B",
          }}
          >
            • Увеличение оборота компании в сети от 20%<br/>
            • Увеличение прибыли от 25%<br/>
            • Объем продаж новинок от 10 тонн/мес <br/>
          </h5>
          <br />
          <br />

          <BS.Container>
            <BS.Row>

              <BS.Col style={{
                paddingLeft: "0px",
              }}>
                <Link to="/files/B_POINT_EXPERT.pdf" 
                      target="_blank" 
                      download 
                      style={{color: "inherit", textDecoration: "none",}}>
                <div style={{
                  backgroundColor: "#fff",
                  padding: "8px",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "rgba(0, 0, 0, 0.6)",
                  boxShadow: "6px 6px 10px rgba(0, 0, 0, 0.2)",
                }} >
                  Скачать презентацию
                </div>
                </Link>
              </BS.Col>

              <BS.Col style={{
                paddingRight: "0px",
              }}>
                <a href="#CalculateProjectBar" style={{color: "inherit", textDecoration: "none",}}>
                  <div style={{
                    backgroundColor: "#2E004B",
                    padding: "8px",
                    textAlign: "center",
                    fontWeight: "bold",
                    boxShadow: "6px 6px 10px rgba(0, 0, 0, 0.2)",
                  }} >
                    Заказать диагностику
                  </div>
                </a>
              </BS.Col>

            </BS.Row>
          </BS.Container>

          <br/>
          <br/>
            *согласно статистике наших клиентов

        </div>
      </div>
    </BS.Stack >
  );
}

//style={{maxHeight: "100vh", }}
//maxHeight: "100vh", 

const Home00 = () => {
  return (
    <div>
      <BS.Row style={{ margin: "0", height: "100%", }}>
        {/* <BS.Col md={7} style={{backgroundColor: "Red", display: "flex", alignItems: "center" }} > */}
        <BS.Col md={7} style={{ backgroundColor: "Red", }} >
          {/* <img style={{position: "absolute", top: "50px", left: "80px", }} width={48} src={logo} alt="" /> */}
          <HomeCarouselCaption />
        </BS.Col>

        <BS.Col md={5} style={{ paddingRight: "0", paddingLeft: "0" }}>
          <img style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
            src={back_image}
            alt="back_image"
          />
        </BS.Col>
      </BS.Row>
    </div>
  );
}

export default Home00;
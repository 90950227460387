import logo from '../../assets/2logo.png';
import * as BS from "react-bootstrap";
import * as RBI from "react-bootstrap-icons";
import { Link, NavLink } from "react-router-dom";
import styles from './NavigationBar.module.css';

const NavigationBar = (props: any) => {

  let className = "";
  if (props.topAbsolute === true) {
    className = "text-white top-absolute";
  } else {
    className = "text-white";
  }

  return (
  //<header className="bg-dark text-white top-absolute"></header>
  //className="text-white top-absolute"
  <header className={className} style={{backgroundColor: "red",}}>
      <BS.Container>
        {/* bg="dark"   */}
        <BS.Navbar collapseOnSelect expand="sm"  variant="dark" className='pt-3'>

          <BS.Navbar.Brand as={Link} to="/" className='me-4'>
            <img className="d-inline-block align-top" width={48} src={logo} alt="logo" />
          </BS.Navbar.Brand>
          
          <BS.Navbar.Toggle aria-controls="navbarScroll"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll" />

          <BS.Navbar.Collapse className='justify-content-center' id="navbarScroll">
            <BS.Nav className='fw-medium'>
              {/* <NavLink eventKey="1" as={Link} to="/">Home</NavLink>
                        <NavLink eventKey="2" as={Link} to="/about">About</NavLink>
                        <NavLink eventKey="3" as={Link} to="/contact">Contact</NavLink> */}
              {/* <BS.NavLink eventKey="3" as={Link} to="/portfolio">Портфолио</BS.NavLink>
              <BS.NavLink eventKey="3" as={Link} to="/services">Услуги</BS.NavLink>

              <BS.NavLink eventKey="3" as={Link} to="/about">О нас</BS.NavLink>
              <BS.NavLink eventKey="3" as={Link} to="/package_solutions">Пакетные решения</BS.NavLink>
              <BS.NavLink eventKey="3" as={Link} to="/faq">FAQ</BS.NavLink>
              <BS.NavLink eventKey="3" as={Link} to="/blogs">Блог</BS.NavLink>
              <BS.NavLink eventKey="3" as={Link} to="/review">Отзывы</BS.NavLink>
              <BS.NavLink eventKey="3" as={Link} to="/contact">Контакты</BS.NavLink> */}

              {/* <BS.NavLink href="#OurProjectsId">Услуги</BS.NavLink> */}
              
              <BS.NavLink href="#ServicesId">Услуги</BS.NavLink>
              <BS.NavLink href="#OurProjectsId">Проекты</BS.NavLink>
              <BS.NavLink href="#ExpertiseId">Опыт</BS.NavLink>
              <BS.NavLink href="#OurClientsId">Клиенты</BS.NavLink>
              <BS.NavLink href="#ContactsId">Контакты</BS.NavLink>

              
              {/* <BS.NavLink href="#OurTeamId">Команда</BS.NavLink> */}
              {/* <BS.NavLink href="#CalculateProjectBar">Заказать услугу</BS.NavLink> */}

            </BS.Nav>
          </BS.Navbar.Collapse>

          <BS.Nav>
            <BS.Stack className='text-end' direction="horizontal" gap={1}>
              {/* <span className='fs-5'>+7 (999) 99-99-99</span>
              <span className='fs-6 text-decoration-underline'>mail@mail.com</span> */}
              {/* <BS.Button variant="dark"> <RBI.Telephone color="Red"/> </BS.Button> */}
              <a href="tel:+7 (981) 166-84-47" className={styles['mssg-link'] + ' '}> <RBI.Telephone color="Black"/> </a>              
              <a href="https://wa.me/79811668447" className={styles['mssg-link'] + ' '}> <RBI.Whatsapp color="Black"/> </a>              
              <a href="https://t.me/+79811668447" className={styles['mssg-link'] + ' '}> <RBI.Telegram color="Black"/> </a>              
            </BS.Stack>
          </BS.Nav>

        </BS.Navbar>
      </BS.Container>
    </header>
  );
}

export default NavigationBar;
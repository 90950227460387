import * as BS from "react-bootstrap";

import React, {useState} from 'react';

const GoToUpButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    }
    else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <a className="u-go-to-v1"
       onClick={scrollToTop}
       href="#"
      //  style={{ display: visible ? 'inline' : 'none' }}
       style={{ display: visible ? 'block' : 'none' }}
      >
    </a>
  );
  // <a className="u-go-to-v1" />
}

export default GoToUpButton;
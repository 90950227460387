import { useLocation } from "react-router-dom";
import * as BS from "react-bootstrap";

const PageNotFound = (props: any) => {
  let location = useLocation();
  return (
    <BS.Container>
      <h1>
        <br /><br /><br /><br />
        Страница {props.location} {location.pathname} не найдена<br />
        <br /><br /><br /><br />
      </h1>
    </BS.Container>
  );
}

export default PageNotFound;
import * as BS from "react-bootstrap";
//import * as RRDom from "react-router-dom";
import styles from './OurWorksBar.module.css';

// import HoverAnimateImage from '../HoverAnimateImage'

import ourWork01Ing from '../../assets/our-works/01.png';
import ourWork02Ing from '../../assets/our-works/02.png';
import ourWork03Ing from '../../assets/our-works/03.jpg';

import ourWork04Ing from '../../assets/our-works/07.jpg';
import ourWork05Ing from '../../assets/our-works/08.jpg';
import ourWork06Ing from '../../assets/our-works/09.jpg';

import ourWork07Ing from '../../assets/our-works/10.png';
import ourWork08Ing from '../../assets/our-works/11.jpg';
import ourWork09Ing from '../../assets/our-works/12.png';

import ourWork10Ing from '../../assets/our-works/13.png';
import ourWork11Ing from '../../assets/our-works/14.png';
import ourWork12Ing from '../../assets/our-works/15.png';

import ourWork13Ing from '../../assets/our-works/16.png';
import ourWork14Ing from '../../assets/our-works/17.png';
import ourWork15Ing from '../../assets/our-works/18.png';

import ourWork16Ing from '../../assets/our-works/19.png';
import ourWork17Ing from '../../assets/our-works/20.png';
import ourWork18Ing from '../../assets/our-works/21.png';

import ourWork19Ing from '../../assets/our-works/22.jpg';
import ourWork20Ing from '../../assets/our-works/23.jpg';
import ourWork21Ing from '../../assets/our-works/24.png';

import ourWork22Ing from '../../assets/our-works/25.png';
import ourWork23Ing from '../../assets/our-works/26.png';
import ourWork24Ing from '../../assets/our-works/27.png';

import ourWork25Ing from '../../assets/our-works/28.png';
import ourWork26Ing from '../../assets/our-works/29.jpg';
import ourWork27Ing from '../../assets/our-works/30.jpg';

// import KhladEskimoImg from './KhladEskimo/khladeskimo-square.jpg';

const MainContainerBackgroundWhiteStyle = { backgroundColor: "white", };
const MainContainerBackgroundGrayStyle = { backgroundColor: "#fafafa", };


const ServicesBarItem = (props: any) => {
  return (
    <BS.Container className={styles['square-style'] + ' mx-0 my-0 px-0 py-0 '}>
        <img className={styles['my-img']} src={props.ImagePath} alt="logo" />
        <div className={styles.overlay}/>

        <div className={styles.textbox}> 
          <div className={styles["textbox-title"] + ' pb-2' } >{props.TextForTitle}</div>
          <div><b>Работы:</b></div>
          <div>{props.TextForList}</div>
        </div>
    </BS.Container>
  );
}

const CarouselItem = (props: any) => {
  return (
    <BS.Container className='py-2'>

      {/* Row 1 */}
      <BS.Row lg='3' md='2' sm='1' xs='1'>

        <BS.Col className='py-4'>
          <ServicesBarItem ImagePath={props.ImagePath1} TextForTitle={props.TextForTitle1} TextForList={props.TextForList1} />
        </BS.Col>

        <BS.Col className='py-4'>
          <ServicesBarItem ImagePath={props.ImagePath2} TextForTitle={props.TextForTitle2} TextForList={props.TextForList2}/>
        </BS.Col>

        <BS.Col className='py-4'>
          <ServicesBarItem ImagePath={props.ImagePath3} TextForTitle={props.TextForTitle3} TextForList={props.TextForList3}/>
        </BS.Col>

        {/* <BS.Col className='py-4'>
          <ServicesBarItem ImagePath={props.ImagePath4}/>
        </BS.Col> */}
      </BS.Row>

    </BS.Container>);
}

const OurWorksBar = (props: any) => {
  let MainContainerStyle: {};
  if (props.BackgroundStyle === "Gray")
    MainContainerStyle = MainContainerBackgroundGrayStyle;
  else
    MainContainerStyle = MainContainerBackgroundWhiteStyle;

  return (
    <div style={MainContainerStyle}>
      <BS.Container className='py-4'>
        <h1 id="OurProjectsId"  className='py-0 section-title'>
          Реализованы проекты
        </h1>
        {/* <br /> */}

        <BS.Carousel className="screen-block"
          //  slide={false}
          interval={null}
        >

          {/* 01-03 */}
          <BS.Carousel.Item >
            <CarouselItem

              ImagePath1={ourWork01Ing}
                TextForTitle1={<span>Разработка ассортиментной линейки 40 SKU под ключ для компании-поставщика HORECA. 
                  Специальный проект для входа в сетевые ритейлы «Азбука вкуса» и «Глобус Гурмэ».
                  </span>}
                TextForList1={<span>
                  •	Продуктовая линейка под ключ совместно с технологом производства<br/>
                  •	Ценообразование<br/>
                  •	Упаковочное решение: особый конструктив обечайки и частичная универсализация упаковки<br/>
                  •	Брендинг под ключ<br/>
                </span>}

              ImagePath2={ourWork02Ing}
                TextForTitle2={<span>Разработка бренда под ключ. Греческие пироги для входа в «Ашан» и «Метро».
                  Задача: изменить готовую продуктовую линейку для увеличения полочной цены на 40%.
                  </span>}
                TextForList2={<span>
                  •	Новое позиционирование для высокого ценового сегмента, легенда<br/>
                  •	Доработка готового продукта под позиционирование<br/>
                  •	Брендинг под ключ: нейм, регистрация Роспатент, концептуальный дизайн, серия<br/>
                </span>}

              ImagePath3={ourWork03Ing}
                TextForTitle3={<span>Разработка универсального пищевого бренда для экспорта в Китай базовых продуктов из России</span>}
                TextForList3={<span>
                  •	Нейминг с регистрацией в России и Китае<br/>
                  •	Концептуальный дизайн упаковки<br/>
                  •	Конструктив упаковки<br/>
                  •	Адаптация концепции на другие продуктовые группы<br/>
                </span>}
          />
            <BS.Carousel.Caption>
            </BS.Carousel.Caption>
          </BS.Carousel.Item>

          {/* 04-06 */}
          <BS.Carousel.Item >
            <CarouselItem
              ImagePath1={ourWork04Ing}
                TextForTitle1={<span>Разработка серии полбяных макарон для сетевого ритейла на общую 
                  бакалейную полку. Бренд под ключ.
                  Задача: уйти от узкой полки ЗОЖ, встать в «средний+» ценовой сегмент.
                  </span>}
                TextForList1={<span>
                  •	Идея, позиционирование, легенда – итальянские особые макароны Farro<br/>
                  •	Ассортиментная линейка из 8 SKU<br/>
                  •	Упаковочное решение <br/>
                  •	Нейминг <br/>
                  •	Регистрация названия в Роспатенте<br/>
                  •	Концептуальный дизайн упаковки в итальянском стиле<br/>
                </span>}

              ImagePath2={ourWork05Ing}
                TextForTitle2={<span>Разработка серии сыровяленых деликатесов на старте эмбарго на 
                  европейские деликатесы - для сетевого ритейла ЦФО и СЗФО. 4 SKU</span>}
                TextForList2={<span>
                  •	Продуктовая линейка под ключ совместно с технологом производства <br/>
                  •	Ценообразование<br/>
                  •	Упаковочное решение<br/>
                  •	Бренд под ключ<br/>
                  •	Презентация для сетевого ритейла <br/>
                </span>}

              ImagePath3={ourWork06Ing}
              TextForTitle3={<span>SOS -Разработка линейки паштетов из мяса кролика для сетевого ритейла РФ. 
                На базе готового решения из нашей базы. Срок реализации – 4 дня.</span>}
              TextForList3={<span>
                  •	Концептуальный дизайн упаковки<br/>
                  •	Серия - 5 SKU <br/>
                  •	Презентация для дистрибьютеров<br/>
              </span>}
            />
            <BS.Carousel.Caption>
            </BS.Carousel.Caption>
          </BS.Carousel.Item>

          {/* 07-09 */}
          <BS.Carousel.Item >
            <CarouselItem
              ImagePath1={ourWork07Ing}
              TextForTitle1={<span>Разработка серии сырных тарелок для сетевых гипермаркетов. 
                Задача: отстроиться от аналогичной продукции конкурента по цене полки, наполнению, визуалу. 
                Добавить целевое ситуативное потребление.<br/>
                Идея: сырно-десертный презент благодарность учителям, врачам, коллегам. 
                Сказать «спасибо!» вместо 1000 конфет…
                </span>}
              TextForList1={<span>
                •	Ассортиментная матрица под ключ – 9 SKU
                •	Ценообразование
                •	Упаковочное решение
                •	Идея, позиционирование 
                •	Бренд под ключ
                •	Авторская акварель
              </span>}

            ImagePath2={ourWork08Ing}
            TextForTitle2={<span>Разработка бренда под ключ в нише замороженных полуфабрикатов на полку 
              сетевому ритейлу с широкой географией продаж. 47 SKU.</span>}
            TextForList2={<span>
              •	Упаковочное решение <br/>
              •	Идея, позиционирование<br/>
              •	Нейминг<br/>
              •	Регистрация нейминга в Роспатент<br/>
              •	Логотип<br/>
              •	Концептуальный дизайн упаковки<br/>
              •	Адаптация концепции на разные форматы упаковки<br/>
              •	Презентация <br/>
              •	POS материалы<br/>
            </span>}

            ImagePath3={ourWork09Ing}
            TextForTitle3={<span>Линейка сырных тарелок -треугольников для двоих. 
              Разработка для сетевого алкогольного ритейла - 7 SKU.
              </span>}
            TextForList3={<span>
              •	Упаковочное решение <br/>
              •	Идея, позиционирование<br/>
              •	Логотип<br/>
              •	Концептуальный дизайн упаковки<br/>
              •	Презентация <br/>
            </span>}
        />
            <BS.Carousel.Caption>
            </BS.Carousel.Caption>
          </BS.Carousel.Item>

          {/* 10-12 */}
          <BS.Carousel.Item >
            <CarouselItem
              ImagePath1={ourWork10Ing}
              TextForTitle1={<span>Разработка первого в России бренда цыплят-корнишонов. 
                 Ресторанный деликатес на сетевых полках – фокус на гипермаркеты. 
                 Бренд под ключ.</span>}
              TextForList1={<span>
                •	Идея по ассортиментному направлению птицефабрики<br/>
                •	Идея, позиционирование <br/>
                •	Упаковочное решение <br/>
                •	Нейминг<br/>
                •	Регистрация нейминга-категории в Роспатент<br/>
                •	Логотип<br/>
                •	Концептуальный дизайн упаковки<br/>
                •	Адаптация концепции на разные форматы упаковки<br/>
              </span>}

              ImagePath2={ourWork11Ing}
              TextForTitle2={<span>Разработка линейки мясных вяленых снеков – джерок для алкогольного ритейла.
                Бренд под ключ.</span>}
              TextForList2={<span>
                •	Идея, позиционирование<br/>
                •	Нейминг<br/>
                •	Регистрация в Роспатент<br/>
                •	Логотип<br/>
                •	Концептуальный дизайн упаковки<br/>
                •	Презентация <br/>
              </span>}

              ImagePath3={ourWork12Ing}
              TextForTitle3={<span>Линейка закусочных сыров для сетевого ритейла с долгим сроком хранения- в баночках с 
                модифицированной газовой средой.</span>}
              TextForList3={<span>
                •	Позиционирование<br/>
                •	Логотип<br/>
                •	Концептуальный дизайн упаковки<br/>
                •	Презентация <br/>
              </span>}
            />
          </BS.Carousel.Item>
          
          {/* 13-15 */}
          <BS.Carousel.Item >
            <CarouselItem
              ImagePath1={ourWork13Ing}
              TextForTitle1={<span>Разработка бренда овощной консервации для сетевых дискаунтеров ЮФО.</span>}
              TextForList1={<span>
               •	Бренд под ключ<br/>
               •	Позиционирование, идея<br/>
               •	Нейминг<br/>
               •	Регистрация нейма в Роспатент<br/>
               •	Логотип<br/>
               •	Концептуальный дизайн упаковки<br/>
               •	Разработка персонажей<br/>
               •	Презентация <br/>
              </span>}

              ImagePath2={ourWork14Ing}
              TextForTitle2={<span>Разработка линейки порционной рыбы и морских деликатесов для сетевых гипермаркетов – 23 SKU.</span>}
              TextForList2={<span>
               •	Универсальное упаковочное решение для стартапа на разные форматы лотков и продуктовых групп.<br/>
               •	Концептуальный дизайн<br/>
               •	Презентация для сетевого ритейла<br/>
              </span>}

              ImagePath3={ourWork15Ing}
              TextForTitle3={<span>Брендинг и упаковочное решение для вкусных штучек в газовой среде: пирожные, сырники и запеканка – 9 SKU.</span>}
              TextForList3={<span>
               •	Позиционирование, идея<br/>
               •	Нейминг с регистрацией<br/>
               •	Логотип<br/>
               •	Концептуальный дизайн упаковки<br/>
               •	Серия ассортимента<br/>
               •	Презентация<br/>
              </span>}
            />
          </BS.Carousel.Item>
         
          {/* 16-18 */}
          <BS.Carousel.Item >
            <CarouselItem
              ImagePath1={ourWork16Ing}
              TextForTitle1={<span>Серия натуральных джемов с интересными добавками специй, трав и цедры для входа в сетевой ритейл ЦФО и ЮФО. 
                Брендинг под ключ.</span>}
              TextForList1={<span>
               •	Концептуальный дизайн упаковки<br/>
               •	Серия ассортимента<br/>
               •	Презентация <br/>
              </span>}

              ImagePath2={ourWork17Ing}
              TextForTitle2={<span>Разработка бренда под ключ - натуральные компоты быстрого заваривания в саше – альтернатива чаю. Задача: встать на сетевые полки в категорию чай.</span>}
              TextForList2={<span>
               •	Позиционирование, идея<br/>
               •	Логотип<br/>
               •	Концептуальный дизайн упаковки<br/>
               •	Серия ассортимента<br/>
               •	Презентация для сетей и дистрибьютеров<br/>
              </span>}

              ImagePath3={ourWork18Ing}
              TextForTitle3={<span>Первая на полках СЗФО линейка премиум стейков в сложных маринадах с топингами.
                 Разработка нового ассортимента, бренд под ключ.
              </span>}
              TextForList3={<span>
               •	Продуктовая мартица под ключ совместно с технологом производства– 7 SKU<br/>
               •	Ценообразование<br/>
               •	Упаковочное решение<br/>
               •	Идея, позиционирование <br/>
               •	Брендинг полного цикла<br/>
              </span>}
            />
          </BS.Carousel.Item>

          {/* 19-21 */}
          <BS.Carousel.Item >
            <CarouselItem
              ImagePath1={ourWork19Ing}
              TextForTitle1={<span>Ребрендинг флагманской пельменной линейки «Элика» для сетевого ритейла РФ.</span>}
              TextForList1={<span>
               •	Концептуальный дизайн упаковки<br/>
               •	Серия упаковки<br/>
              </span>}

              ImagePath2={ourWork20Ing}
              TextForTitle2={<span>Нашли ключевые клиентские ценности нового продукта и упаковали серию. 
                Готовые продукты компании «Элика».</span>}
              TextForList2={<span>
               •	Разработка ключевых ценностей линейки<br/>
               •	Концептуальны дизайн<br/>
               •	Адаптация решения на смежные группы продуктов<br/>
               •	Серия упаковки<br/>
              </span>}

              ImagePath3={ourWork21Ing}
              TextForTitle3={<span>Разработка упаковки соли для экспорта в Китай для ОАО Тыретский солерудник. <br/>
                Задача: Идентификация китайцами упаковки, как 100% русского продукта.
                </span>}
              TextForList3={<span>
               •	Реализовали идею, зашив восприятие российского флага в концепцию упаковки. <br/>
               •	Концептуальный дизайн.<br/>
               •	Сопровождение в типографии.<br/>
               •	Подготовка к выставке в Шанхае.<br/>
              </span>}
            />
          </BS.Carousel.Item>

          {/* 22-24 */}
          <BS.Carousel.Item >
            <CarouselItem
              ImagePath1={ourWork22Ing}
              TextForTitle1={<span>Ребрендинг упаковки охлажденного кролика - лидера продаж в сетевом ритейле по мясу кролика.<br/>
                Задача: выйти из стагнации.
              </span>}
              TextForList1={<span>
               •	Нашли ключевые клиентские ценности продукта<br/>
               •	Показали на уровне восприятия как продукт для детей<br/>
               •	Ребрендинг под ключ<br/>
               •	Авторская графика<br/>
               •	+15% прироста<br/>
              </span>}

              ImagePath2={ourWork23Ing}
              TextForTitle2={<span>Нашли ключевые клиентские ценности нового продукта и упаковали серию. 
                Готовые продукты компании «Элика».</span>}
              TextForList2={<span>
               •	Разработка ключевых ценностей линейки<br/>
               •	Концептуальны дизайн<br/>
               •	Адаптация решения на смежные группы продуктов<br/>
               •	Серия упаковки<br/>
              </span>}

              ImagePath3={ourWork24Ing}
              TextForTitle3={<span>SOS -Разработка линейки соусов для сетевого ритейла. На базе готового решения из нашей базы. 
                Срок реализации – 3 дня.</span>}
              TextForList3={<span>
               •	Концептуальный дизайн<br/>
               •	Серия упаковки<br/>
              </span>}
            />
          </BS.Carousel.Item>

          {/* 25-27 */}
          <BS.Carousel.Item >
            <CarouselItem
              ImagePath1={ourWork25Ing}
              TextForTitle1={<span>Разработка бренда чесночной пасты для маркетплейсов под ключ. 6SKU.</span>}
              TextForList1={<span>
               •	Разработка ключевых ценностей линейки<br/>
               •	Нейминг с регистрацией<br/>
               •	Логотип<br/>
               •	Концептуальны дизайн<br/>
               •	Серия упаковки<br/>
              </span>}

              ImagePath2={ourWork26Ing}
              TextForTitle2={<span>Разработка сырных тарелок для алкогольного ритейла «Красное&Белое» под ключ.</span>}
              TextForList2={<span>
               •	Логотип<br/>
               •	Упаковочное решение<br/>
               •	Концептуальны дизайн<br/>
               •	Серия упаковки<br/>
               •	Презентация<br/>
              </span>}

              ImagePath3={ourWork27Ing}
              TextForTitle3={<span>Разработка бренда замороженных полуфабрикатов для сетевых дискаунтеров. Бренд под ключ.</span>}
              TextForList3={<span>
               •	Позиционирование, идея<br/>
               •	Нейминг с регистрацией в Роспатент<br/>
               •	Логотип<br/>
               •	Концептуальный дизайн упаковки<br/>
               •	Презентация <br/>
               •	Каталог<br/>
              </span>}
            />
          </BS.Carousel.Item>

        </BS.Carousel>
      </BS.Container>
    </div>
  );
}

export default OurWorksBar;
import * as BS from "react-bootstrap";
//import * as RRDom from "react-router-dom";
import styles from './LentaTextsBar.module.css';
import Slider from "react-slick";

// import HoverAnimateImage from '../HoverAnimateImage'

import Client01Ing from '../../assets/our-clients/1.png';

// import KhladEskimoImg from './KhladEskimo/khladeskimo-square.jpg';

//const MainContainerBackgroundWhiteStyle = { backgroundColor: "white", };
const MainContainerBackgroundWhiteStyle = { backgroundColor: "#2E004B", };
const MainContainerBackgroundGrayStyle = { backgroundColor: "#fafafa", };


const ServicesBarItem = (props: any) => {
  return (
    <BS.Container className={styles['square-style'] + ' mx-1 my-0 px-0 py-0 '}>
      {/* <img className={styles['my-img']} src={props.ImagePath} alt="logo" /> */}
      <span>{props.Text}</span>
    </BS.Container>
  );
}

const LentaTextsBar = (props: any) => {
  let MainContainerStyle: {};

  if (props.BackgroundStyle === "Gray")
    MainContainerStyle = MainContainerBackgroundGrayStyle;
  else
    MainContainerStyle = MainContainerBackgroundWhiteStyle;

  const SliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    // slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    variableWidth: true,
  };

  return (
    <div style={MainContainerStyle}>
      <BS.Container className='py-2'>
        <div className={styles['slider-container'] + ' '}>
          <Slider className="py-1"
            {...SliderSettings}
          >
            {/* <ServicesBarItem Text={Client01Ing} /> */}
            <ServicesBarItem Text=" Увеличение оборота • "/>
            <ServicesBarItem Text=" Продажи • "/>
            <ServicesBarItem Text=" Оптимизация ассортимента • "/>
            <ServicesBarItem Text=" Новинки • "/>
            <ServicesBarItem Text=" Маркетинг • "/>
            <ServicesBarItem Text=" Дизайн упаковки • "/>
            <ServicesBarItem Text=" Полиграфия • "/>
            <ServicesBarItem Text=" Ценообразование • "/>
            <ServicesBarItem Text=" Увеличение прибыли • "/>
            <ServicesBarItem Text=" Нейминг •"/>
            <ServicesBarItem Text=" Продуктовая матрица •"/>
            <ServicesBarItem Text="Айдентика •"/>
          </Slider>
        </div>
      </BS.Container>
    </div>
  );
}

export default LentaTextsBar;
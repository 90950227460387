import React from 'react';
import logo from './logo.svg';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import FooterBar from './components/FooterBar';
import PageNotFound from './components/PageNotFound';
import GoToUpButton from './components/GoToUpButton';
import NavBarAndContent from './components/NavBarAndContent';
import Home from './components/Home';

function App() {
  return (
    <div className="App">
      {/* <BrowserRouter basename="/bpoint-brand"> */}
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<NavBarAndContent navTopAbsolute={false} contentElement={<Home />} />} />

          <Route path='/*' element={<PageNotFound />} />
        </Routes>
        <FooterBar />
      </BrowserRouter>
      <GoToUpButton />
    </div>
  );
}

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default App;

import * as BS from "react-bootstrap";

import CalculateProjectForm from './CalculateProjectForm'

const MainContainerBackgroundGrayStyle = { backgroundColor: "#fafafa", };

const CalculateProjectBar = () => {
  return (
    <div id="CalculateProjectBar" style={MainContainerBackgroundGrayStyle}>
      <br/><br/>
      <BS.Container className="px-0" >
        <CalculateProjectForm />        
      </BS.Container>
      <br/><br/>
    </div>
  );
}

export default CalculateProjectBar;
import * as BS from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from '../assets/1logo.png';
//import './white-link.css';

// bg="dark" variant="dark"
//className="text-white"

const FooterBar = () => {
  return (
    // <footer className="text-center text-white fixed-bottom" >
    //className="bg-dark text-white py-5 light-link"
    <footer 
      className="text-white py-2 light-link width-to-screen"
      style={{
        backgroundColor: "#2E004B",
      }}      
    >
      <br />
      <BS.Container id="ContactsId" fluid='sm'>
        <BS.Row lg={4} xs={1}>

        {/* className="text-center" */}
          <BS.Col>
            <BS.Stack className="h-100">
              <div className="flex-fill align-content-center" >
                <Link to='./'>
                  <img width={48} src={logo} alt="logo" />
                </Link>
              </div>
            </BS.Stack>
          </BS.Col>

          {/* <BS.Col>
          <BS.Stack gap={1}>
            <div className="text-light fw-bold text-opacity-75">Компания</div>
            <div> <Link to='./about'>О нас</Link></div> 
            <div><Link to='./blog'>Блог</Link></div>
            <div><Link to='./contacts'>Контакты</Link></div>
            <div><a href='./contacts'>Контакты (href)</a></div>
          </BS.Stack>
        </BS.Col>
        <BS.Col>
          <BS.Stack gap={1}>
            <div className="text-light fw-bold text-opacity-75">Услуги</div>
            <div>Продуктовый консалтинг</div>
            <div>Нейминг</div>
            <div>Логотипы</div>
            <div>Фирменный стиль</div>
            <div>Дизайн упаковки</div>
          </BS.Stack>
        </BS.Col>
        <BS.Col>
          <BS.Stack gap={1}>
            <br/>
            <br/>
            <div>Ребрендинг и рестайлинг</div>
            <div>Упаковочные решения</div>
            <div>Полиграфия</div>
            <div>Интерьерные решения</div>
          </BS.Stack>
        </BS.Col> */}

          <BS.Col></BS.Col>
          <BS.Col></BS.Col>

          <BS.Col>
            <BS.Stack gap={1}>
              <div className="text-light text-opacity-75">По будням с 10.00 до 20.00</div>
              <div className="text-light fw-bold fs-5">+7 (981) 166-84-47</div>
              
              <br/>
              <div className="text-light text-opacity-75">Электронная почта</div>
              <div className="text-light fw-bold fs-5">mail@mail.com</div>
            </BS.Stack>
          </BS.Col>
        </BS.Row>
      </BS.Container>
      <br />
    </footer>
  );
}

export default FooterBar;
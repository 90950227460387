import * as BS from "react-bootstrap";
//import * as RRDom from "react-router-dom";
import styles from './OurClientsBar.module.css';
import Slider from "react-slick";

// import HoverAnimateImage from '../HoverAnimateImage'

import Client01Ing from '../../assets/our-clients/1.png';
import Client02Ing from '../../assets/our-clients/2.png';
import Client03Ing from '../../assets/our-clients/3.png';
import Client04Ing from '../../assets/our-clients/4.png';
import Client05Ing from '../../assets/our-clients/5.png';
import Client06Ing from '../../assets/our-clients/6.png';
import Client07Ing from '../../assets/our-clients/7.png';
import Client08Ing from '../../assets/our-clients/7.1.png';
import Client09Ing from '../../assets/our-clients/8.png';
import Client10Ing from '../../assets/our-clients/9.png';
import Client11Ing from '../../assets/our-clients/10.png';

import Client12Ing from '../../assets/our-clients/11.png';
import Client13Ing from '../../assets/our-clients/12.png';
import Client14Ing from '../../assets/our-clients/13.png';
import Client15Ing from '../../assets/our-clients/14.png';
import Client16Ing from '../../assets/our-clients/15.png';
import Client17Ing from '../../assets/our-clients/16.png';
import Client18Ing from '../../assets/our-clients/17.png';
import Client19Ing from '../../assets/our-clients/18.jpg';
import Client20Ing from '../../assets/our-clients/19.png';
import Client21Ing from '../../assets/our-clients/20.png';
import Client22Ing from '../../assets/our-clients/21.png';

// import KhladEskimoImg from './KhladEskimo/khladeskimo-square.jpg';

const MainContainerBackgroundWhiteStyle = { backgroundColor: "white", };
const MainContainerBackgroundGrayStyle = { backgroundColor: "#fafafa", };


const ServicesBarItem = (props: any) => {
  return (
    <BS.Container className={styles['square-style'] + ' mx-0 my-0 px-0 py-0 '}>
      <img className={styles['my-img']} src={props.ImagePath} alt="logo" />
      {/* <div className={styles.overlay}/>

        <div className={styles.textbox}> 
          <div className={styles["textbox-title"] + ' pb-2' } >{props.TextForTitle}</div>
          <div><b>Работы:</b></div>
          <div>{props.TextForList}</div>
        </div> */}
    </BS.Container>
  );
}

const CarouselItem = (props: any) => {
  return (
    <BS.Container className='py-2'>

      {/* Row 1 */}
      <BS.Row lg='11' md='11' sm='11' xs='11'>

        <BS.Col className='px-1'>
          <ServicesBarItem ImagePath={props.ImagePath1} />
        </BS.Col>

        <BS.Col className='px-1'>
          <ServicesBarItem ImagePath={props.ImagePath2} />
        </BS.Col>

        <BS.Col className='px-1'>
          <ServicesBarItem ImagePath={props.ImagePath3} />
        </BS.Col>

        <BS.Col className='px-1'>
          <ServicesBarItem ImagePath={props.ImagePath4} />
        </BS.Col>

        <BS.Col className='px-1'>
          <ServicesBarItem ImagePath={props.ImagePath5} />
        </BS.Col>

        <BS.Col className='px-1'>
          <ServicesBarItem ImagePath={props.ImagePath6} />
        </BS.Col>

        <BS.Col className='px-1'>
          <ServicesBarItem ImagePath={props.ImagePath7} />
        </BS.Col>

        <BS.Col className='px-1'>
          <ServicesBarItem ImagePath={props.ImagePath8} />
        </BS.Col>

        <BS.Col className='px-1'>
          <ServicesBarItem ImagePath={props.ImagePath9} />
        </BS.Col>

        <BS.Col className='px-1'>
          <ServicesBarItem ImagePath={props.ImagePath10} />
        </BS.Col>

        <BS.Col className='px-1'>
          <ServicesBarItem ImagePath={props.ImagePath11} />
        </BS.Col>

        {/* <BS.Col className='py-4'>
          <ServicesBarItem ImagePath={props.ImagePath4}/>
        </BS.Col> */}
      </BS.Row>

    </BS.Container>);
}

const OurWorksBar = (props: any) => {
  let MainContainerStyle: {};

  if (props.BackgroundStyle === "Gray")
    MainContainerStyle = MainContainerBackgroundGrayStyle;
  else
    MainContainerStyle = MainContainerBackgroundWhiteStyle;

  const SliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div style={MainContainerStyle}>
      <BS.Container className='py-4'>
        <h1 id="OurClientsId" className='py-0 section-title'>
          Наши клиенты
        </h1>
        <br />
        <div className='slider-container'>
          <Slider className="screen-block py-4"
            {...SliderSettings}
          >
            <ServicesBarItem ImagePath={Client01Ing} />
            <ServicesBarItem ImagePath={Client02Ing} />
            <ServicesBarItem ImagePath={Client03Ing} />
            <ServicesBarItem ImagePath={Client04Ing} />
            <ServicesBarItem ImagePath={Client05Ing} />
            <ServicesBarItem ImagePath={Client06Ing} />
            <ServicesBarItem ImagePath={Client07Ing} />
            <ServicesBarItem ImagePath={Client08Ing} />
            <ServicesBarItem ImagePath={Client09Ing} />
            <ServicesBarItem ImagePath={Client10Ing} />
            <ServicesBarItem ImagePath={Client11Ing} />
            <ServicesBarItem ImagePath={Client12Ing} />
            <ServicesBarItem ImagePath={Client13Ing} />
            <ServicesBarItem ImagePath={Client14Ing} />
            <ServicesBarItem ImagePath={Client15Ing} />
            <ServicesBarItem ImagePath={Client16Ing} />
            <ServicesBarItem ImagePath={Client17Ing} />
            <ServicesBarItem ImagePath={Client18Ing} />
            <ServicesBarItem ImagePath={Client19Ing} />
            <ServicesBarItem ImagePath={Client20Ing} />
            <ServicesBarItem ImagePath={Client21Ing} />
            <ServicesBarItem ImagePath={Client22Ing} />

            {/* <img className={styles['my-img']} src={Client01Ing} alt="logo" /> */}
            {/* <ServicesBarItem ImagePath={Client06Ing} /> */}
          </Slider>
        </div>
      </BS.Container>
    </div>
  );
}

// const OurWorksBar = (props: any) => {
//   let MainContainerStyle: {};
//   if (props.BackgroundStyle === "Gray")
//     MainContainerStyle = MainContainerBackgroundGrayStyle;
//   else
//     MainContainerStyle = MainContainerBackgroundWhiteStyle;

//   return (
//     <div style={MainContainerStyle}>
//       <BS.Container className='py-4'>
//         <h1 className='py-0 section-title'>
//           Наши клиенты
//         </h1>
//         <br />

//         <BS.Carousel className="screen-block py-4"
//           //  slide={false}
//           interval={2500}
//         >

//           {/* 01-11 */}
//           <BS.Carousel.Item >
//             <CarouselItem
//               ImagePath1={Client01Ing}
//               ImagePath2={Client02Ing}
//               ImagePath3={Client03Ing}
//               ImagePath4={Client04Ing}
//               ImagePath5={Client05Ing}
//               ImagePath6={Client06Ing}
//               ImagePath7={Client07Ing}
//               ImagePath8={Client07_1Ing}
//               ImagePath9={Client08Ing}
//               ImagePath10={Client09Ing}
//               ImagePath11={Client10Ing}
//             />
//           </BS.Carousel.Item>

//           {/* 12-22 */}
//           <BS.Carousel.Item >
//             <CarouselItem
//               ImagePath1={Client11Ing}
//               ImagePath2={Client12Ing}
//               ImagePath3={Client13Ing}
//               ImagePath4={Client14Ing}
//               ImagePath5={Client15Ing}
//               ImagePath6={Client16Ing}
//               ImagePath7={Client17Ing}
//               ImagePath8={Client18Ing}
//               ImagePath9={Client19Ing}
//               ImagePath10={Client20Ing}
//               ImagePath11={Client21Ing}
//             />
//           </BS.Carousel.Item>

//         </BS.Carousel>
//       </BS.Container>
//     </div>
//   );
// }

export default OurWorksBar;
import * as BS from "react-bootstrap";
import * as RRDom from "react-router-dom";
import styles from './TeamBar.module.css';

// import CompetitorsCoolerSign from '../../assets/service-glyphs/Конкуренты круче.png';

const TeamBarItem = (props: any) => {
  return (

    <RRDom.NavLink to={props.HRef} className="text-decoration-none text-reset">
      <BS.Container className={styles['square-style'] + ' py-2 px-4 '}
      >
        <div className={styles.content}>
          {/* <img className="d-inline-block align-top mb-2" width={48} src={props.SignPath} alt="logo" /> */}
          <h4>
            {props.Title}
          </h4>
          <p className={styles['short-text']}>
            {props.ShortText}
          </p>
          <p className={styles['long-text']}>
            {props.children}
          </p>
        </div>
      </BS.Container>
    </RRDom.NavLink>
  );
}


const TeamBar = () => {
  return (
    <BS.Container className='py-0'>
      {/* <h1 id="OurTeamId" className="section-title">
        Команда
      </h1> */}

      {/* Row 1 */}
      <BS.Row lg='3' md='2' sm='1' xs='1'>
        {/* <BS.Col className='py-4'>
          <TeamBarItem
            SignPath={ServiceSignConsulting}
            Title="Наши клиенты"
            ShortText={<span>
              •	Пищевые производства, работающие с сетевым ритейлом<br />
              •	Пищевые производства, у которых есть планы по входу в сетевой ритейл<br />
              •	80% малый бизнес  <br />
            </span>}
          >
          </TeamBarItem>
        </BS.Col> */}

        <BS.Col className='py-4'>
          <TeamBarItem
            // SignPath={CompetitorsCoolerSign}
            Title="Кто мы?"
            ShortText={<span>
              •	B-POINT- не молодые и очень опытные специалисты.<br />
              •	Постоянная команда - у каждого более 10 000 часов в своей узкой сфере.<br />
              •	Наша команда в тандеме с вашими сотрудниками = выход вашей компании или бренда на новый уровень.<br />
              •	Мы понимаем задачи и потребности сетевого ритейла: большой опыт работы с СТМ крупных торговых сетей по разработке продуктов со стороны производителя и визуалов для СТМ по бренд-букам со стороны сетей.<br />
            </span>}
          >
          </TeamBarItem>
        </BS.Col>

        <BS.Col className='py-4'>
          <TeamBarItem
            // SignPath={CompetitorsCoolerSign}
            Title="Что умеем?"
            ShortText={<span>
              •	Выстраиваем/оптимизируем продуктовую матрицу бренда или весь ассортиментный портфель компании<br />
              •	Разрабатываем новые продукты в тандеме с вашей командой – от идеи до готового продукта<br />
              •	Строим бренды с нуля под ключ<br />
              •	Решаем точечно вашу задачу. Посмотрим на ваш готовый продукт в контексте конкретных сетевых полок и докрутим текущее продуктовое решение.<br />
            </span>}
          >
          </TeamBarItem>
        </BS.Col>

        <BS.Col className='py-4'>
          <TeamBarItem
            // SignPath={CompetitorsCoolerSign}
            Title="Что еще?"
            ShortText={<span>
              Поддержка: В течении 6 месяцев со дня подписания акта приемки внесем все технические правки в разработанные нами макеты по требованию сетей бесплатно.<br />
              Все, чтобы вы получили не просто услугу, а сервис, заботу и прирост прибыли компании!
            </span>}

          >
          </TeamBarItem>
        </BS.Col>
      </BS.Row>

      {/* <br/>
      <BS.Row>
      <b>ВЫДАЕМ РЕЗУЛЬТАТ. В НУЖНЫЙ СРОК. ПО СОГЛАСОВАННОЙ СТОИМОСТИ.</b>
      </BS.Row> */}

    </BS.Container>
  );
}

export default TeamBar;
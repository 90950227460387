import * as BS from "react-bootstrap";

//import Contact from '../Contact'
import Nata from './Me.jpg'

const ImgStyle = { maxWidth: "400px", width: "100%" };

const AboutUsAndFotosBar = () => {
  return (
    <BS.Container>
      <br />
      <h1 id="ExpertiseId" className='py-2 section-title'>
        Экспертный опыт
      </h1>
      <br />

      {/* Row 1 */}
      {/* <BS.Row sm='3' xs='1'> */}
      <BS.Row >
        <BS.Col md={4} className='py-2'>
          {/* <img className="d-inline-block align-top" width={48} src={logo} alt="logo" />  */}
          <img className="d-inline-block align-top" style={ImgStyle} src={Nata} alt="logo" />
        </BS.Col>

        <BS.Col md={8} className='py-2'>

          <div className="border px-3 py-3">
            <h6 className="lh-base text-uppercase" style={{color: "Red", }}>
              Если вы обратились в В'POINT, значит, я веду ваш проект.<br />
              Значит, мы вместе идем в Вашу точку Б. Выгодно и быстро.<br />
              Я – управляющий студии В'POINT, маркетолог-стратег, брендолог.<br />
            </h6>
          </div>
          <br />

          <b>МОЙ ЛИЧНЫЙ ОПЫТ:</b> <br />
          <br />
          •	Работа НА пищевых производствах (средний бизнес) в маркетинге – 9 лет<br />
          •	Работа С пищевыми производствами ( малый и средний бизнес) с дистрибуцией в сетевой ритейл в качестве маркетинга на аутсорсинге – 11 лет, 35 довольных производств.<br />
          •	Аудит 4P маркетинга – 20 лет<br />
          •	Разработано готовых продуктов в связке с технологами пищевых производств – 400+<br />
          •	Разработано брендов с нуля- от идеи продукта до полки в сетевом ритейле – 130+<br />
          •	Ребрендинг продуктовых линеек – органолептика, конструктив упаковки, вес единицы, визуалы, наполнение новыми смыслами -57<br />
          •	Разработка брендов для сетевого ритейла Китая с нуля: нейминг с регистрацией на Китай, концепция, дизайн упаковки – 3<br />
          •	Аудит и оптимизация упаковки – 20 лет<br />
          <br />
          <br />
        </BS.Col>

      </BS.Row>

      {/* <br /><br />
      <p><b>КОМАНДА:</b></p>
      <p><b>Умеем.</b> Команда – постоянные сотрудники с опытом работы от 15 лет каждый в своей области. Креативщики, графические дизайнеры, копирайтеры, конструкторы, дизайнеры интерьеров.</p>
      <p><b>Понимаем.</b> Что хочет видеть сетевой ритейл : большой опыт работы с СТМ крупных торговых сетей по бренд-букам.</p>
      <p><b>Знаем.</b> Знание материалов упаковки и пре-пресс. Типографию тоже можем посоветовать.</p>
      <p><b>Берем на себя труд.</b> Сопровождаем клиента до желаемой вами точки Б.</p>
      <p><b>ВЫДАЕМ РЕЗУЛЬТАТ. В НУЖНЫЙ СРОК. ПО СОГЛАСОВАННОЙ СТОИМОСТИ.</b></p>
      <br />
      <br /> */}
    </BS.Container>
  );
}

export default AboutUsAndFotosBar;
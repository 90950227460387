import * as BS from "react-bootstrap";
import * as RRDom from "react-router-dom";
import styles from './ServicesBar.module.css';
import ServicesBarItem from "./ServicesBarItem";

//import ServiceSignCons from '../../assets/service-glyphs/конс.png';
import CompetitorsCoolerSign from '../../assets/service-glyphs/Конкуренты круче.png';
import NewBrandSign from '../../assets/service-glyphs/новый бренд.png';
import NewProductSign from '../../assets/service-glyphs/новый продукт.png';
import DiscussHypothesesSign from '../../assets/service-glyphs/обсудить гипотизу.png';
import OptimizeSign from '../../assets/service-glyphs/оптимизировать.png';
import SalesFallingSign from '../../assets/service-glyphs/падают продажи.png';
import SosSign from '../../assets/service-glyphs/сос.png';
import BoostSign from '../../assets/service-glyphs/Усилить.png';

const ServicesBar = () => {
  return (
    <BS.Container className='py-5'>
      <h1 id="ServicesId" className="section-title">
        Что нужно решить, чтобы прийти в точку Б?
      </h1>

      {/* Row 1 */}
      {/* <BS.Row sm='4' xs='1'>  */}
      <BS.Row lg='4' md='2' sm='1' xs='1'>
        <BS.Col className='py-4'>
          {/* <BS.Container className= {styles['square-style'] + ' py-5 px-4 text-decoration-none text-reset'} */}
          <RRDom.NavLink to="#" className="text-decoration-none text-reset">
            <BS.Container className={styles['square-style'] + ' py-5 px-4 '} >
              <div className={styles['content']}>
                <img className="d-inline-block align-top mb-2" width={48} src={NewBrandSign} alt="logo" />

                <h5>Нужен новенький бренд</h5>

                <p className={styles['short-text']}>
                Ваш технолог разработал готовый продукт, теперь его нужно одеть. <br/>Разработаем бренд с нуля для входа в сети или в конкретную сеть
                </p>

                <p className={styles['long-text']}>
                Ваш технолог разработал готовый продукт, теперь его нужно одеть. <br/>Разработаем бренд с нуля для входа в сети или в конкретную сеть: <br />
                  • позиционирование <br />
                  • нейминг с регистрацией <br />
                  • логотип <br />
                  • концептуальный дизайн упаковки <br />
                  • тиражирование упаковки <br />
                  • презентация бренда <br />
                  Подтянем продукт в тандеме с вашими сотрудниками под полку/требования конкретного ритейла. <br />
                  <b>Поддержка:</b> 6 месяцев со дня подписания акта приемки – внесем все технические правки в макеты по требованию сетей бесплатно.
                </p>
              </div>
            </BS.Container>
          </RRDom.NavLink>
        </BS.Col>


        <BS.Col className='py-4'>
          {/* <RRDom.NavLink to="/portfolio/neomed"> */}
          <ServicesBarItem
            SignPath={NewProductSign}
            Title="Нужен новый продукт"
            ShortText="Разработаем продуктовую линейку под ключ для входа в сетевой ритейл"
          // LongText="Разрабатываем продуктовые линейки под ключ для входа в сетевой ритейл: поиск свободной продуктовой ниши под возможности вашего производства, ценообразование, органолептика совместно с вашим технологом, конструктив упаковки, брендинг полного цикла."
          >
            <span>
              Разработаем продуктовую линейку под ключ для входа в сетевой ритейл:<br />
              • поиск свободной продуктовой ниши под возможности вашего производства<br />
              • ценообразование<br />
              • органолептика совместно с вашим технологом<br />
              • конструктив упаковки<br />
              • брендинг полного цикла<br />
              <b>Поддержка:</b> 6 месяцев со дня подписания акта приемки – внесем все технические правки в макеты по требованию сетей бесплатно.
            </span>
          </ServicesBarItem>
          {/* </RRDom.NavLink> */}
        </BS.Col>

        <BS.Col className='py-4'>
          <ServicesBarItem
            SignPath={SalesFallingSign}
            Title="Уже в сети, но продажи не устраивают"
            ShortText="Ваш продукт уже лежит в сетевом ритейле, но плановые показатели продаж не выполняются"
          // LongText="Ваш продукт уже лежит в сетевом ритейле, но плановые показатели продаж не выполняются. Проаудируем 4P вашего бренда с конкурентами и найдем точки роста. Решаем точечно вашу задачу. Посмотрим на ваш готовый продукт в контексте конкретных сетевых полок и ответим, что докрутить в текущем брендинге."
          >
            <span>
              Ваш продукт уже лежит в сетевом ритейле, но плановые показатели продаж не выполняются. <br />
              Проаудируем 4P вашего бренда с конкурентами и найдем точки роста. <br />
              Посмотрим на ваш готовый продукт в контексте конкретных сетевых полок и ответим, что докрутить в текущем брендинге.<br />
              Это может быть органолептика, ценообразование, объем/вес продукта, упаковка, отсутствие УТП, 
              не верно выбранное место продаж или не та целевая полка. Вариантов много, вместе разберемся.
            </span>
          </ServicesBarItem>
        </BS.Col>

        <BS.Col className='py-4'>
          <ServicesBarItem
            SignPath={SosSign}
            Title="SOS! Нужны срочно макеты, опаздываем с образцами"
            ShortText="Срочная работа по разработке макетов, когда сети просит образцы, а у вас даже логотипа нет"
            // LongText="Срочная работа по разработке макетов, когда сети просит образцы, а у вас даже логотипа нет. У нас накоплена большая база уникальных заготовок в разных категориях для таких случаев. Обращайтесь, подберем для вас экспресс-вариант, согласуем концепцию и доработаем под вашу задачу. Напечатаете мини-партию в типографии у дома и упакуете готовый продукт для образцов. "
          >
            <span>
            Срочная работа по разработке макетов, когда сети просит образцы, а у вас даже логотипа нет. <br />
            У нас накоплена большая база уникальных заготовок в разных категориях для таких случаев. <br />
            Обращайтесь, подберем для вас экспресс-вариант, согласуем концепцию и доработаем под вашу задачу. <br />
            Напечатаете мини-партию в типографии у дома и упакуете готовый продукт для образцов.<br />
            Срок реализации - 3-5 рабочих дней.
            </span>
          </ServicesBarItem>
        </BS.Col>
      </BS.Row>

      {/* Row 2 */}
      <BS.Row lg='4' md='2' sm='1' xs='1'>
        <BS.Col className='py-4'>
          <ServicesBarItem
            SignPath={CompetitorsCoolerSign}
            Title="Конкуренты сильно круче выглядят на полке"
            ShortText="Стратегия развития вашей компании предполагает сотрудничество с сетевым ритейлом"
            // LongText="Стратегия развития вашей компании предполагает сотрудничество с сетевым ритейлом. Вы планируете сделать предложение, аудируете полку сети в вашей категории, и понимаете, что визуал вашего бренда не сможет конкурировать на сетевой полке.  Обращайтесь, поймем ключевые клиентские ценности вашего продукта и разработаем бренд с нуля, или произведем рестайлинг или ребрендинг текущего. У нас мощные дизайнеры, специализирующиеся на продуктовом брендинге."
          >
            <span>
              Стратегия развития вашей компании предполагает сотрудничество с сетевым ритейлом. <br />
              Вы планируете сделать предложение, аудируете полку сети в вашей категории, и понимаете, 
              что визуал вашего бренда не сможет конкурировать на сетевой полке.  <br />
              Обращайтесь, поймем ключевые клиентские ценности вашего продукта и разработаем бренд с нуля, 
              или произведем рестайлинг или ребрендинг текущего. <br />
              У нас мощные дизайнеры, специализирующиеся на продуктовом брендинге.
            </span>
          </ServicesBarItem>
        </BS.Col>

        <BS.Col className='py-4'>
          <ServicesBarItem
            SignPath={DiscussHypothesesSign}
            Title="Обсудить вашу боль или гипотезу – разговор с экспертом"
            ShortText="Консультация 1-1.5 часа с практикующим маркетологом"
            // LongText="Консультация 1-1.5 часа с практикующим маркетологом. Большая насмотренность в разных продуктовых группах, широкий опыт и компетенции в продуктовом маркетинге, брендинге. Любые вопросы по ассортиментным стратегиям и брендингу."
          >
            <span>
              Консультация 1-1.5 часа с практикующим маркетологом.<br />
              Большая насмотренность в разных продуктовых группах, широкий опыт и компетенции в продуктовом маркетинге, брендинге. <br />
              Любые вопросы по ассортиментным стратегиям и брендингу.
            </span>
          </ServicesBarItem>
        </BS.Col>

        <BS.Col className='py-4'>
          <ServicesBarItem
            SignPath={BoostSign}
            Title="Найти и усилить ключевые клиентские ценности продукта"
            ShortText="Ваше главное УТП «соотношение цена/качество»? Тогда вам нужно поискать еще"
            // LongText="Ваше главное УТП «соотношение цена/качество»? Тогда вам нужно поискать еще. Посмотрим на ваш продукт глазами маркетинга и поймем, за что его любит уже или сможет полюбить ваш покупатель. Или найдем, что докрутить в ГП и поможем внедрить."
          >
            <span>
              Ваше главное УТП «соотношение цена/качество»? Тогда вам нужно поискать еще. <br />
              Посмотрим на ваш продукт глазами маркетинга и поймем, за что его любит уже или сможет полюбить ваш покупатель.<br />
              Или найдем, что докрутить в ГП и поможем внедрить.
            </span>
          </ServicesBarItem>
        </BS.Col>

        <BS.Col className='py-4'>
          <ServicesBarItem
            SignPath={OptimizeSign}
            Title="Оптимизировать ассортиментный портфель"
            ShortText="Оптимизация ассортиментного портфеля предполагает часть времени в офф-лайн формате"
            // LongText="Выпускаете много SKU, половина не продается, жалко утилизировать остатки упаковки? Бренд содержит такой ассортимент, что отдел продаж не может его запомнить? Много разных визуалов упаковки под одним брендом, не понятно, как оптимизировать? Один бренд содержит ассортимент с разным ценовым позиционированием? И много других вопросов, оптимизируя которые, вы увеличите прибыль компании. Оптимизация ассортиментного портфеля предполагает часть времени в офф-лайн формате."
          >
            <span>
            Выпускаете много SKU, половина не продается, жалко утилизировать остатки упаковки? <br />
            • Бренд содержит такой ассортимент, что отдел продаж не может его запомнить? <br />
            • Много разных визуалов упаковки под одним брендом, не понятно, как оптимизировать?<br /> 
            • Один бренд содержит ассортимент с разным ценовым позиционированием? <br />
            • И много других вопросов, оптимизируя которые, вы увеличите прибыль компании. <br />
            • Оптимизация ассортиментного портфеля предполагает часть времени в офф-лайн формате.
            </span>
          </ServicesBarItem>
        </BS.Col>

      </BS.Row>

      <BS.Stack gap={2} className="col-md-5 mx-auto">
        {/* <BS.Button variant="outline-primary"> 
            <a href="#CalculateProjectBar"> 
               Заказать услугу  
            </a>
           </BS.Button> */}
        {/* <br /> */}
        <a href="#CalculateProjectBar" className="btn btn-outline-secondary" role="button">Заказать услугу</a>
      </BS.Stack>

    </BS.Container>
  );
}

export default ServicesBar;
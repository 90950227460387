import * as BS from "react-bootstrap";

const Header1Style = { color: "#512E5F", fontWeight: "bold", };
const Text1Style = { color: "#1d242c", };
const MainContainerStyle = { backgroundColor: "#fafafa", };

const Home01 = () => {
  return (
    <div style={MainContainerStyle}>
    <BS.Container >
      <BS.Row sm='4' xs='1' className="text-center">
        <BS.Col className='py-3 px-4'>
          <h1 style={Header1Style} >15 лет</h1>
          <p className="fs-5" style={Text1Style} >
            Разрабатываем продуктовые линейки по всей Рoссии
          </p>
        </BS.Col>

        <BS.Col  className='py-3 px-4'>
        <h1 style={Header1Style} >35</h1>
          <p className="fs-5" style={Text1Style} >
          Производств FMCG - <br/> наши клиенты
          </p>
        </BS.Col>

        <BS.Col  className='py-3 px-4'>
          <h1 style={Header1Style} >130+</h1>
          <p className="fs-5" style={Text1Style} >
            Проектов брендинга с нуля разработано нами
          </p>
        </BS.Col>

        <BS.Col  className='py-3 px-4'>
        <h1 style={Header1Style} >97%</h1>
          <p className="fs-5" style={Text1Style} >
            Клиентов рекомендуют нас коллегам и партнерам
          </p>
        </BS.Col>
      </BS.Row>
    </BS.Container>
    </div>
  );
}

export default Home01;